import React, { useState } from "react"

import Layout from "../../components/layout"
import TicTacToe from "../../components/TicTacToe"
import Gist from '../../components/gistWrapper'

export default function Blog() {

	// Declare a new state variable, which we'll call "count"
	const [count, setCount] = useState(3);

	// tictactoeInstance.size = count;

	return (
		<Layout>
			<article className="blog">
				<header className="entry-header">
					<h1 className="entry-title">Tic Tac Toe</h1>
					<div className="entry-meta">
						<span className="sep">Posted on </span>
						<time className="entry-date">Oct 4, 2021</time>
					</div>
				</header>
				<div className="entry-content">
					<p>A simple game of Tic-Tac-Toe using the <a href="https://p5js.org/">P5JS</a> library.</p>
					<div align="center">
						<p>
						<button onClick={() => setCount(3)}>3x3</button>
						<button onClick={() => setCount(4)}>4x4</button>
						<button onClick={() => setCount(5)}>5x5</button>
						</p>

						<TicTacToe size={count} displayMouse={false}/>
					</div>

					<p>The gist below shows you the javascript classes using two main class types.</p>
					<p>BoardGame describes the rules of the game, but does not contain any P5 library specific drawing features.</p>
					<p>BoardGameSketch renders the board with all the current pieces using the P5 library drawing features.</p>
					<p><Gist id='552d42eeda874f71d2b786e4e8b9b208' /></p>
				</div>
			</article>
		</Layout>
	)
}
