import React, { Component } from "react"
import p5 from 'p5/lib/p5.min'


/*
* Player class handles player name (X or O) and the current score for the player
*/
class Player {
	name;
	score = 0;
	constructor(name) {
		this.name = name;
	}
}
class PlayerX extends Player {
	constructor() {
		super("X");
	}
}
class PlayerO extends Player {
	constructor() {
		super("O");
	}
}

/*
* Board Game deals with just the business rules for the tic tac toe game.  It handles all the status, messages, and data array to store the board game
* It does not manage the drawing of the game on the UI.  That's managed by BoardGameSketch class.
*/
class BoardGame {
	size; // how many rows and columns for the board
	p1;
	p2;

	currentPlayer;  // who's current turn is it
	pieces = [[]];  // all spots on the board.  Empty string means it's available, otherwise it stores the player who picked that piece (p1, p1)
	gameStatus = null; // indicator if the game is finished or not.  null means the game is in play, otherwise the status is "X", "O", or "Tied"

	constructor(size, p1, p2) {
		this.size = size;
		this.p1 = p1;
		this.p2 = p2;

		this.clearGame();
	}
	// clean board to brand new
	clearGame() {
		this.gameStatus = null;
		this.pieces = new Array(this.size).fill("").map(() => new Array(this.size).fill(""));
		this.currentPlayer = this.p1;
	}
	isGameFinished() {
		return this.gameStatus !== null;
	}

	isBoardPieceOpen(position) {
		return (this.getBoardPiece(position) === "")
	};
	setBoardPiece(position, value) {
		this.pieces[position.row][position.col] = value;
	}
	getBoardPiece(position) {
		return this.pieces[position.row][position.col];
	}
	flipPlayer() {
		// flip turn to alternate user;
		this.currentPlayer = (this.currentPlayer === this.p1 ? this.p2 : this.p1);
	}

	selectSpot(position) {
		// if game is finished, clear the board
		if (this.isGameFinished()) {
			this.clearGame();
			return;
		}

		// if the spot is currently taken, then cannot choose it
		if (!this.isBoardPieceOpen(position)) {
			return;
		}

		// can pick spot
		this.setBoardPiece(position, this.currentPlayer);
		// check if we've finished the game
		this.checkGame(position)
		// flip turn to alternate user;
		this.flipPlayer();
	}
	checkGame(position) {
		if (this.checkWinningColumn(position)
			|| this.checkWinningRow(position)
			|| this.checkWinningDiagnolRowBLTR(position)
			|| this.checkWinningDiagnolRowTLBR(position)
		) {
			let winner = this.getBoardPiece(position);
			this.gameStatus = winner.name;
			winner.score += 1;
			return;
		}

		// check if pieces still open
		for (var r = 0; r < this.size; r++) {
			for (var c = 0; c < this.size; c++) {
				if (this.isBoardPieceOpen({ row: r, col: c })) {
					// a piece is still available to play
					return;
				}
			}
		}
		// there are no further open pieces
		this.gameStatus = "Tied";
	}
	checkWinningLine(positions) {
		let lastValue = null;
		for (let position of positions) {
			var currValue = this.getBoardPiece(position)

			// piece is open
			if (currValue === "") {
				return false;
			}
			if (lastValue === null) {
				lastValue = currValue;
			} else if (lastValue !== currValue) {
				// line contains different values, not a winning line
				return false;
			}
		}
		return true;
	}
	checkWinningColumn(position) {
		var collection = [];
		for (var i = 0; i < this.size; i++) {
			collection.push({ label: "column", row: i, col: position.col });
		}
		return this.checkWinningLine(collection);
	}
	checkWinningRow(position) {
		var collection = [];
		for (var i = 0; i < this.size; i++) {
			collection.push({ label: "row", row: position.row, col: i });
		}
		return this.checkWinningLine(collection);
	}
	checkWinningDiagnolRowTLBR(position) {
		// check if position is on diagnol
		if (position.row !== position.col) {
			// is not on diagnol
			return false;
		}

		var collection = [];
		for (var i = 0; i < this.size; i++) {
			collection.push({ label: "dtlbr", row: i, col: i });
		}
		return this.checkWinningLine(collection);
	}

	checkWinningDiagnolRowBLTR(position) {
		// check if position is on diagnol
		if ((position.row !== position.col) && (Math.abs(position.row - (this.size - 1) !== position.col))) {
			// is not on diagnol
			return false;
		}

		var collection = [];
		for (var i = 0; i < this.size; i++) {
			collection.push({ label: "dbltr", row: Math.abs(i - (this.size - 1)), col: i });
		}
		return this.checkWinningLine(collection)
	}

	getScoreMessages() {
		return [
			`Player ${this.p1.name}: ${this.p1.score}`,
			`Player ${this.p2.name}: ${this.p2.score}`
		];
	}
	getStatusMessage() {
		return `It is player ${this.currentPlayer.name}'s turn`
	}
	getWinningStatusMessage() {
		let s = `Game over. `
		if (this.gameStatus === "Tied") {
			s += "The game was tied."
		} else {
			s += `Player ${this.gameStatus} won the game.`
		}
		s += ' Click anywhere to play another game'
		return s;
	}

	getPlayedPieces() {
		var playedPieces = [];
		for (var row = 0; row < this.pieces.length; row++) {
			for (var col = 0; col < this.pieces[0].length; col++) {
				var position = { row, col };
				if (!this.isBoardPieceOpen(position)) {
					let player = this.getBoardPiece(position);
					playedPieces.push({ col, row, player: player })
				}
			}
		}
		return playedPieces;
	}
}




// Helper class to draw X board piece
class SketchPlayerX {
	static draw(sketch, sqrSize, center, line) {
		sketch.stroke(line.color);
		sketch.strokeWeight(line.weight);

		let x1 = center.x - ((sqrSize.width - line.padding) / 2);
		let y1 = center.y - ((sqrSize.height - line.padding) / 2);
		let x2 = x1 + sqrSize.width - line.padding;
		let y2 = y1 + sqrSize.height - line.padding;

		sketch.line(x1, y1, x2, y2);
		sketch.line(x1, y2, x2, y1);

		sketch.noStroke();
		sketch.strokeWeight(0)
	}
}
// Helper class to draw O board piece
class SketchPlayerO {
	static draw(sketch, sqrSize, center, line) {
		sketch.stroke(line.color);
		sketch.strokeWeight(line.weight);

		sketch.circle(center.x, center.y, sqrSize.width - line.padding)

		sketch.noStroke();
		sketch.strokeWeight(0);
	}
}

// Helper Factory to draw current player piece
class DrawPlayerFactory {
	static drawSketchPlayer(player, sketch, sqrSize, center, line) {
		if (player instanceof PlayerX) {
			return SketchPlayerX.draw(sketch, sqrSize, center, line);
		}
		return SketchPlayerO.draw(sketch, sqrSize, center, line);
	}
}

/*
* BoardGameSketch manages the visual drawing aspects of the tic tac toe board game using Sketch and P5.js
*/
class BoardGameSketch {
	displayMouse=true;
	sketch;
	size; // number of rows and columns

	boardGame;
	options;
	spotSize; // width and height of an individual spot on the board
	currentPosition = { row: 0, col: 0 } // current place of mouse within grid of tic tac toe board

	constructor(sketch, options, p1, p2, size = 5, displayMouse=true) {
		this.styleOptions = {
			bar_bgcolor: "purple",
			bar_txtcolor: "white",
			line_color: 'purple',
			line_weight: 1,

			main_color: 'black',
			main_padding: 20,
			mouse: { color: 'black', weight: 2, padding: 20 },
			playedpieces: { color: 'purple', weight: 2, padding: 20 }
		}
		this.displayMouse = displayMouse;
		this.sketch = sketch;
		this.options = options;

		this.changeSize(size);

		this.boardGame = new BoardGame(this.size, p1, p2);
		this.sketch.setup = this.setup.bind(this);
		this.sketch.draw = this.draw.bind(this);
		this.sketch.mousePressed = this.mousePressed.bind(this);
	}

	setup() {
		this.sketch.createCanvas(this.options.board_width, this.options.board_height + this.options.score_height + this.options.status_height + this.options.debug_height);
	}
	changeSize(size) {
		this.size = size;
		this.spotSize = this._spotDimension(this.options);
		if (this.boardGame) {
			this.boardGame.size = this.size;
			this.boardGame.clearGame();
		}
	}

	draw() {
		this.sketch.background(240);
		this.drawGame();
	}

	mousePressed() {
		this.boardGame.selectSpot(this.currentPosition);
	}

	drawGame() {
		this.drawTopScoreBar();
		if (!this.boardGame.isGameFinished()) {
			this.drawBoardLines();
			this.drawMouseCursorForPlayer(this.styleOptions.mouse)
			this.drawPlayedPieces(this.styleOptions.playedpieces);
		} else {
			this.drawWinningStatusMessage()
		}
		this.drawBottomStatusBar();
	}

	_spotDimension(canvas) {
		return { width: Math.floor(canvas.board_width / this.size), height: Math.floor(canvas.board_height / this.size) }
	}

	_drawBar(yStart) {
		// draw bar background
		this.sketch.fill(this.styleOptions.bar_bgcolor);
		this.sketch.rect(0, yStart + 0, this.options.board_width, this.options.score_height);
		this.sketch.noFill();
	}
	drawTopScoreBar() {
		let scoreMessages = this.boardGame.getScoreMessages();

		// starting y position
		let yStart = 0;

		// draw bar background
		this._drawBar(yStart);

		// display score
		this.sketch.fill(this.styleOptions.bar_txtcolor);
		let x = 10;
		let y = yStart + 8;
		this.sketch.text(scoreMessages[0], x, y, this.options.board_width / 2, this.options.score_height); // Text wraps within text box
		this.sketch.text(scoreMessages[1], x + this.options.board_width / 2, y, this.options.board_width / 2, this.options.score_height); // Text wraps within text box
		this.sketch.noFill();
	}

	drawBottomStatusBar() {
		let s = this.boardGame.getStatusMessage();

		// starting y position
		let yStart = this.options.score_height + this.options.board_height;

		// draw bar background
		this._drawBar(yStart);

		// display status
		this.sketch.fill(this.styleOptions.bar_txtcolor);
		let x = 10;
		let y = yStart + 8;
		this.sketch.text(s, x, y, this.options.board_width, this.options.score_height); // Text wraps within text box
		this.sketch.noFill();
	}

	drawBoardLines() {
		// starting y position
		let yStart = this.options.score_height;

		// draw canvas border
		this.sketch.stroke(this.styleOptions.line_color);
		this.sketch.strokeWeight(this.styleOptions.line_weight);

		let nOfCols = this.size - 1;
		for (let i = 0; i < nOfCols; i++) {
			let colX1 = this.spotSize.width * (i + 1);
			this.sketch.line(colX1, yStart, colX1, yStart + this.options.board_height);
		}

		let nOfRows = this.size - 1;
		for (let i = 0; i < nOfRows; i++) {
			let rowY1 = this.spotSize.height * (i + 1);
			this.sketch.line(0, yStart + rowY1, this.options.board_width, yStart + rowY1);
		}

		this.sketch.noStroke();
		this.sketch.strokeWeight(0);
	}

	drawWinningStatusMessage() {
		let s = this.boardGame.getWinningStatusMessage();

		// starting y position
		let yStart = this.options.score_height;

		// list out score
		this.sketch.fill(this.styleOptions.main_color);
		let x = 10;
		let y = yStart + 8;
		this.sketch.text(s, x, y, this.options.board_width - this.styleOptions.main_padding, this.options.board_height); // Text wraps within text box
		this.sketch.noFill();
	}

	getCurrentMousePosition() {
		// board game starts
		let yStart = this.options.score_height;

		// make x within 0 and less than board width;
		let mouseX = this.sketch.mouseX;
		mouseX = Math.min(mouseX, this.options.board_width);
		mouseX = Math.max(mouseX, 0);

		// make y within 0 and less than board height;
		let mouseY = this.sketch.mouseY - yStart;
		mouseY = Math.min(mouseY, this.options.board_height);
		mouseY = Math.max(mouseY, 0);

		// figure out which row and column we're in
		let row = Math.floor(mouseY / this.spotSize.height);
		row = Math.max(0, row);
		row = Math.min(row, this.size - 1);

		let col = Math.floor(mouseX / this.spotSize.width);
		col = Math.max(0, col);
		col = Math.min(col, this.size - 1);
		return { row, col }
	}
	_getCenterXYCoordinatesOfPosition(position) {
		let yStart = this.options.score_height;
		let x1 = this.spotSize.width * position.col;
		let y1 = yStart + (this.spotSize.height * position.row);
		let center = { x: x1 + (this.spotSize.width / 2), y: y1 + (this.spotSize.height / 2) }
		return center;
	}

	drawMouseCursorForPlayer(line) {
		this.currentPosition = this.getCurrentMousePosition();

		if (!this.displayMouse) {
			return;
		}
		if (this.boardGame.isBoardPieceOpen(this.currentPosition)) {
			let center = this._getCenterXYCoordinatesOfPosition(this.currentPosition);
			DrawPlayerFactory.drawSketchPlayer(this.boardGame.currentPlayer, this.sketch, this.spotSize, center, line)
		}
	}

	drawPlayedPieces(line) {
		var playedPieces = this.boardGame.getPlayedPieces();
		for (var playedPiece of playedPieces) {
			let center = this._getCenterXYCoordinatesOfPosition(playedPiece);
			DrawPlayerFactory.drawSketchPlayer(playedPiece.player, this.sketch, this.spotSize, center, line);
		}
	}
	/*
		drawDebugMessage(s) {
			let x = 10;
			let y = this.options.score_height + this.options.board_height + this.options.status_height;
	
			this.sketch.fill(50);
			this.sketch.text(s, x, y + 10, 70, 80); // Text wraps within text box
			this.sketch.noFill();
		}
	*/
}

class TicTacToe extends Component {
	constructor(props) {
		super(props);
		this.myRef = React.createRef();
	}

	Sketch = (sketch) => {
		var options = { board_width: 200, board_height: 200, score_height: 25, status_height: 25, debug_height: 0 }
		var p1 = new PlayerX();
		var p2 = new PlayerO();

		this.boardgame = new BoardGameSketch(sketch, options, p1, p2, this.props.size, this.props.displayMouse)
	}

	componentDidMount() {
		this.myP5 = new p5(this.Sketch, this.myRef.current)
	}
	componentDidUpdate() {
		this.boardgame.changeSize(this.props.size);
		this.boardgame.displayMouse = this.props.displayMouse;
	}

	render() {
		return (
			<div ref={this.myRef}></div>
		)
	}
}


export default TicTacToe;

